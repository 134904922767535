<template>
  <div class='px-2 py-2 relative'>
    <div class='flex flex-row justify-between px-2 py-2 font-semibold'>
      <div class='flex flex-row'>
        <h1 class='px-2 py-2'>{{ title }}</h1>
        <button
          class='flex-shrink-0 py-2 pl-2 pr-3 mx-2 bg-gray-100 border border-gray-300 hover:border-blue-300 hover:bg-gray-50 hover:shadow-md hover:text-gray-900 flex flex-row justify-start items-center rounded-md text-sm gap-x-2 uppercase'
          @click='addPiFill'>
          <plus-circle-icon class='inline-block h-5' />
          추가
        </button>
        <button
          class='flex-shrink-0 py-2 pl-2 pr-3 mx-2 bg-gray-100 border border-gray-300 hover:border-blue-300 hover:bg-gray-50 hover:shadow-md hover:text-gray-900 flex flex-row justify-start items-center rounded-md text-sm gap-x-2 uppercase'
          :disabled='filteredNewData.length === 0'
          @click='confirmAddAll'>
          <save-icon class='inline-block h-5' />
          일괄저장
        </button>
      </div>
      <button
        class='justify-end rounded-md px-4 py-2 text-white text-xs font-semibold'
        style='background-color: #223645;'
        @click='confirmCalcuate'>
        포트폴리오 재산출
      </button>
    </div>
    <div class='overflow-auto' style='height: calc(100% - 4rem);'>
      <table class='text-xs items-stretch'>
        <thead>
          <tr class='border-b whitespace-no-wrap text-left'>
            <th class='px-2 py-2 sticky top-0 z-10'>날짜</th>
            <th class='px-2 py-2 sticky top-0 z-10'>매매구분</th>
            <th class='px-2 py-2 sticky top-0 z-10'>체결구분</th>
            <th class='px-2 py-2 text-right sticky top-0 z-10'>수량</th>
            <th class='px-2 py-2 text-right sticky top-0 z-10'>거래금액</th>
            <th class='px-2 py-2 text-right sticky top-0 z-10'>가격</th>
            <th class='px-2 py-2 text-right sticky top-0 z-10'>수수료</th>
            <th class='px-2 py-2 text-right sticky top-0 z-10'>세금</th>
            <th class='px-2 py-2 text-right sticky top-0 z-10'></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for='fill in existsData' :key='`${fill.id}-${fill.index}`'
            class='border-b border-gray-100 hover:bg-blue-100 cursor-pointer'
            :class='fillStyle(fill.action)'>
            <td class='px-2 py-2'>
              <el-date-picker
                v-model='fill.createdOn'
                value-format="yyyy-MM-dd"
                type='date'
                size='small'
                style='width: 8rem;'
                placeholder='Pick a day'
                :clearable='false'
                @change='updateExistsData(fill)'>
              </el-date-picker>
            </td>
            <td class='px-2 py-2 uppercase'>
              <select v-model='fill.action'
                class='w-20 form-select uppercase'
                style='font-size: 0.7rem;'
                @change='updateExistsData(fill)'>
                <option v-for='action in actionTypes'
                        :key='action'
                        :value='action'>
                  {{ action }}
                </option>
              </select>
            </td>
            <td class='px-2 py-2'>
              <select v-model='fill.fillType'
                class='form-select w-20'
                style='width: 7rem; font-size: 0.7rem;'
                @change='updateExistsData(fill)'>
                <option v-for='fill in fillTypes'
                        :key='fill.value'
                        :value='fill.value'>
                  {{ fill.name }}
                </option>
              </select>
            </td>
            <td class='px-2 py-2 text-right tabular-number'>
              <vue-numeric
                v-model='fill.quantity'
                output-type='number'
                class='form-input text-xs text-right'
                style='width: 8rem; font-size: 0.7rem;'
                separator=','
                :minus='true'
                @change='updateExistsData(fill)'>
              </vue-numeric>
            </td>
            <td class='px-2 py-2 text-right tabular-number'>
              <vue-numeric
                v-model='fill.transactionValue'
                output-type='number'
                class='form-input text-xs text-right'
                style='width: 8rem; font-size: 0.7rem;'
                separator=','
                :minus='true'
                @change='updateExistsData(fill)'>
              </vue-numeric>
            </td>
            <td class='px-2 py-2 text-right tabular-number'>
              <vue-numeric
                v-model='fill.price'
                output-type='number'
                class='form-input text-xs text-right'
                style='width: 100px; font-size: 0.7rem;'
                separator=','
                :precision='2'
                :minus='true'
                @change='updateExistsData(fill)'>
              </vue-numeric>
            </td>
            <td class='px-2 py-2 text-right tabular-number'>
              <vue-numeric
                v-model='fill.fee'
                output-type='number'
                class='form-input text-xs text-right'
                style='width: 100px; font-size: 0.7rem;'
                separator=','
                :minus='true'
                @change='updateExistsData(fill)'>
              </vue-numeric>
            </td>
            <td class='px-2 py-2 text-right tabular-number'>
              <vue-numeric
                v-model='fill.tax'
                output-type='number'
                class='form-input text-xs text-right'
                style='width: 100px; font-size: 0.7rem;'
                separator=','
                :minus='true'
                @change='updateExistsData(fill)'>
              </vue-numeric>
            </td>
            <td class='px-2 py-2'>
              <button
                class='block border pl-3 pr-4 py-2 rounded-md text-xs lg:text-sm text-gray-700 border-gray-300 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
                @click='confirmDelete(fill)'>
                삭제
              </button>
            </td>
            <td class='px-2 py-2'>
              <button
                v-if='fill.id === 0'
                :disabled='validData(fill)'
                class='block border pl-3 pr-4 py-2 rounded-md text-xs lg:text-sm text-gray-700 border-gray-300 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
                @click='confirmAdd(fill)'>
                저장
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { PlusCircleIcon, SaveIcon } from '@vue-hero-icons/outline'
import { cloneDeep } from 'lodash'

export default {
  name: 'PiFillTable',
  components: {
    PlusCircleIcon,
    SaveIcon,
  },
  props: [
    'itemName',
    'shareCode',
  ],
  data () {
    return {
      existsData: [],
      isUpdate: true,
    }
  },
  watch: {
    itemName: {
      handler: function () {
        this.existsData = cloneDeep(this.tmsPiFills.filter(data => data.name === this.itemName))
      },
      immediate: true,
    },
    isUpdate: {
      handler: function (newVal) {
        if (newVal) {
          this.existsData = cloneDeep(this.tmsPiFills.filter(data => data.name === this.itemName))
          this.isUpdate = false
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('piPortfolios', [
      'tmsPiFills',
      'fillTypes',
      'actionTypes',
      'emptyFill',
    ]),
    title () {
      return `${this.itemName} (${this.shareCode})`
    },
    filteredNewData () {
      return this.existsData.filter(data => data.id === 0)
    },
  },
  methods: {
    ...mapActions('piPortfolios', [
      'createFill',
      'updateFill',
      'deleteFill',
      'calculatePiPortfolio',
    ]),
    ...mapMutations('piPortfolios', [
      'addTmsPiFill',
    ]),
    fillStyle (action) {
      if (action.toUpperCase() === 'BUY') {
        return 'profit-text-color'
      } else if (action.toUpperCase() === 'SELL') {
        return 'loss-text-color'
      } else {
        return 'text-gray-500'
      }
    },
    fillType (fillType) {
      let found = this.fillTypes.find(type => type.value === fillType)
      if (found) {
        return found.name
      } else {
        return ''
      }
    },
    confirmCalcuate () {
      this.$confirm(`포트폴리오 재산출 하시겠습니까??`, 'Warning', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning'
      }).then(() => {
        this.calculatePiPortfolio().then(() => {
          this.$message({
            type: 'success',
            message: '재산출 완료'
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '취소'
        })
      })
    },
    validData (fill) {
      if (fill.createdOn && fill.action && fill.quantity > 0) {
        return false
      } else {
        return true
      }
    },
    confirmAdd (fill) {
      this.$confirm(`저장 하시겠습니까??`, 'Warning', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning'
      }).then(() => {
        this.createFill(fill).then(() => {
          this.isUpdate = true
          this.$message({
            type: 'success',
            message: '저장 완료'
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '저장 취소'
        })
      })
    },
    confirmAddAll () {
      this.$confirm(`일괄저장 하시겠습니까??`, 'Warning', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning'
      }).then(() => {
        let promiseArray = []
        for (let i=0; i < this.filteredNewData.length; i++) {
          promiseArray.push(this.createFill(this.filteredNewData[i]))
        }
        Promise.all(promiseArray).then(() => {
          this.isUpdate = true
          this.$message({
            type: 'success',
            message: '저장 완료'
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '저장 취소'
        })
      })
    },
    confirmDelete (fill) {
      if (fill.id === 0) {
        let index = this.existsData.findIndex(newFill => newFill.index === fill.index)
        if (index >= 0) {
          this.existsData.splice(index, 1)
        }
      } else {
        this.$confirm(`삭제 하시겠습니까??`, 'Warning', {
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          type: 'warning'
        }).then(() => {
          this.deleteFill(fill.id).then(() => {
            this.isUpdate = true
            this.$message({
              type: 'success',
              message: '삭제 완료'
            })
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '삭제 취소'
          })
        })
      }
    },
    addPiFill () {
      let newFill = cloneDeep(this.emptyFill)
      newFill.name = this.itemName
      newFill.shareCode = this.shareCode
      newFill.index = this.existsData.length + 1
      this.existsData.push(newFill)
    },
    updateExistsData (fill) {
      if (fill.id > 0) {
        this.updateFill(fill).then(() =>
          this.isUpdate = true
        )
      }
    },
  }
}
</script>

<style lang='scss' scoped>
  @media (min-width: 1024px) {
    .content-container {
      height: calc(100vh - 6rem);
    }
  }
</style>
